import React from 'react';
import TouchableHomeSizeCarousel from './TouchableHomeSizeCarousel';
import MoveDate from './MoveDate';
import TypicalsToggle from './TypicalsToggle';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';

const mapStateToProps = ({ movePlan, common }) => ({
	loading: movePlan.loading,
	homeSizeSelected: common.homeSizeSelected,
	isEmbed: common.isEmbed,
	embedMoverId: common.embedMoverId,
	embedMoverSchemeColor: common.embedMoverSchemeColor
});

const DnaFormBot = props => {
	console.log('props formBot', props)
	return (
		<div className="flex-row">
			<div
				className={`home-date-container ${
					props.homeSizeSelected && 'home-date-container-selected-homesize'
				} ${
					props.isEmbed && 'embed'
				}`}>
				<TouchableHomeSizeCarousel
					setFieldValue={props.setFieldValue}
					addressKey="home_size_id"
					homeSizeSelected={props.homeSizeSelected}
					isEmbed={props.isEmbed}
					schemeColor={props.embedMoverSchemeColor ? props.embedMoverSchemeColor : undefined }
				/>
				{props.homeSizeSelected && (
					<>
						<MoveDate
							values={props.values}
							setFieldValue={props.setFieldValue}
							addressKey="move_date"
							handleBlur={props.handleBlur}
						/>
						{
							props.isEmbed &&
							<TypicalsToggle
								values={props.values}
								setFieldValue={props.setFieldValue}
								addressKey="add_typicals"
								handleBlur={props.handleBlur}
							/>
						}
						<button
							type="submit"
							disabled={props.loading}
							className="get-moving-btn"
							style={props.embedMoverSchemeColor !== undefined ? 
									{backgroundColor: props.embedMoverSchemeColor} : 
									undefined}
						>
							{props.loading ? (
								<ReactLoading
									className="m-auto"
									type={'spokes'}
									color={'#fff'}
									height={24}
									width={24}
								/>
							) : (
								<p>Get Moving!</p>
							)}
						</button>
					</>
				)}

				{/* this overlow div is to hide safari bottom bar */}
				<div className="bottom-safari"></div>
			</div>
		</div>
	);
};

export default connect(mapStateToProps, {})(DnaFormBot);
