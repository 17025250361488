import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import PageNotFound from '../common/PageNotFound';
import { connect } from 'react-redux';
import Header from '../common/Header';
import { useMedia } from '../start/landing/customHooks';

const AsyncInventory = lazy(() => import('../moving/inventory/Inventory'));
const AsyncReview = lazy(() => import('./review/Review'));
const AsyncReviewMovePlan = lazy(() =>
	import('../moving/review/ReviewMovePlan')
);
const AsyncDetails = lazy(() => import('../moving/details/Details'));
const AsyncBook = lazy(() => import('../moving/book/Book'));
const AsyncPackingServices = lazy(() =>
	import('../moving/inventory/PackingServices')
);
const AsyncSpecialHandling = lazy(() =>
	import('../moving/inventory/SpecialHandling')
);
const AsyncCongrats = lazy(() => import('../moving/congrats/Congrats'));
/* const AsyncMoveHistory = lazy(() => import("../myhome/MoveHistory"));
 */
const mapStateToProps = ({ movePlan, router, common }) => ({
	mpid: movePlan.currentMPID,
	isOverviewPage: router.location.pathname.indexOf('congrats') >= 0,
	isEmbed: common.isEmbed,
	embedMoverId: common.embedMoverId
});

const MovingContainer = props => {
	const viewModel = useMedia(
		[
			'(min-width: 1440px)',
			'(min-width: 993px)',
			'(min-width: 768px)',
			'(min-width: 1px)',
		],
		['wide', 'desktop', 'tablet', 'mobile'],
		'desktop'
	);
	return (
		<div className="moving-view-wrapper">
			<Header changeTheme={true} />
			{/* { !props.isOverviewPage && <ProgressWizard/>} */}
			<Switch>
				<Route
					path="/moving/:mpid/review/additional-services"
					render={() => (
						<Suspense fallback={<Loader isEmbed={props.isEmbed} embedMoverId={props.embedMoverId} />}>
							<AsyncPackingServices {...props} />
						</Suspense>
					)}
				/>
				<Route
					path="/moving/:mpid/review/special-handling"
					render={() => (
						<Suspense fallback={<Loader isEmbed={props.isEmbed} embedMoverId={props.embedMoverId} />}>
							<AsyncSpecialHandling {...props} />
						</Suspense>
					)}
				/>
				<Route
					exact
					path="/moving/:mpid/inventory"
					render={() => (
						<Suspense fallback={<Loader isEmbed={props.isEmbed} embedMoverId={props.embedMoverId} />}>
							<AsyncInventory {...props} />
						</Suspense>
					)}
				/>
				<Route
					path="/moving/:mpid/details"
					render={() => (
						<Suspense fallback={<Loader isEmbed={props.isEmbed} embedMoverId={props.embedMoverId} />}>
							<AsyncDetails {...props} viewModel={viewModel} />
						</Suspense>
					)}
				/>
				<Route
					path="/moving/:mpid/review"
					render={() => (
						<Suspense fallback={<Loader isEmbed={props.isEmbed} embedMoverId={props.embedMoverId} />}>
							<AsyncReview {...props} />
						</Suspense>
					)}
				/>
				<Route
					path="/moving/:mpid/plan"
					render={() => (
						<Suspense fallback={<Loader isEmbed={props.isEmbed} embedMoverId={props.embedMoverId} />}>
							<AsyncReviewMovePlan {...props} />
						</Suspense>
					)}
				/>
				<Route
					path="/moving/:mpid/book"
					render={() => (
						<Suspense fallback={<Loader isEmbed={props.isEmbed} embedMoverId={props.embedMoverId} />}>
							<AsyncBook {...props} />
						</Suspense>
					)}
				/>
				<Route
					path="/moving/:mpid/congrats"
					render={() => (
						<Suspense fallback={<Loader isEmbed={props.isEmbed} embedMoverId={props.embedMoverId} />}>
							<AsyncCongrats {...props} />
						</Suspense>
					)}
				/>
				{/* <Route exact path="/my-home/move-history" render={() => <Suspense fallback={<Loader />}><AsyncMoveHistory {...props} viewModel={ viewMode l} /></Suspense>} />  */}
				<Route component={PageNotFound} />
			</Switch>
		</div>
	);
};

export const Loader = (props) => (
	<img
		alt="loading"
		style={{ 
			margin: '40px auto',
			filter:props.isEmbed ? 'grayscale(1)' : 'initial'
		}}
		src="/img/compare_loader.gif"
	/>
);

export default connect(mapStateToProps)(MovingContainer);
