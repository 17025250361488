import React, { useState } from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import { CancelOutlined } from '@material-ui/icons';
import { HOME_SIZES_SELECT } from '../../../../constants';
import { setHomeSizeSelected } from '../../../../redux/actions/common';

const TouchableHomeSizeCarousel = props => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [carouselClicked, setCarouselClicked] = useState(false);

	const handleSelect = (selectedIndex, e) => {
		setActiveIndex(selectedIndex);
		props.setFieldValue(
			props.addressKey,
			HOME_SIZES_SELECT[selectedIndex].value
		);
		setCarouselClicked(false);
	};
	const onCarouselClicked = () => {
		if (!props.homeSizeSelected) {
			setTimeout(() => {
				setCarouselClicked(!carouselClicked);	
				props.setHomeSizeSelected(true);
				// props.setHomeSizeSelected(!carouselClicked);
			}, 320);
		} else {
			setCarouselClicked(!carouselClicked);
			props.setHomeSizeSelected(false);
		}
	};

	const handleCancel = () => {
		setCarouselClicked(false)
		// setTimeout(() => {
		props.setHomeSizeSelected(false);
		// }, 320);
	}

	const slides = HOME_SIZES_SELECT.map(option => {
		return (
			<Carousel.Item
				className={`home-size-carouselItem  ${props.isEmbed ? 'is-embed' : ''} ${
					carouselClicked && 'carousel-clicked'
				}`}
				key={option.value}
				onClick={() => onCarouselClicked()}>
					<button type='button'>
						<button onClick={handleCancel} className='cancel-selection__homeSize' type='button'>
							<CancelOutlined />
						</button>
						<div className="carousel-img-container">
							<img 
								src={option.src} 
								alt={option.type} 
								className="carousel-image"
								style={props.schemeColor ? {filter: 'grayscale(1)'} : undefined} 
							/>
						</div>
						<Carousel.Caption 
							className="carousel-captions"
						>
							<h3
							style={props.schemeColor ? {color: props.schemeColor} : undefined} 
							>{option.text}</h3>
							<p
							style={props.schemeColor ? {color: props.schemeColor} : undefined} 
							>{option.subText}</p>
						</Carousel.Caption>
						<div className='select-cta__container' style={props.schemeColor ? {color: props.schemeColor, borderColor: props.schemeColor} : {color: 'gray', borderColor: 'gray'}}>
							<p>Select</p>
						</div>
					</button>
			</Carousel.Item>
		);
	});
	// next prev btn is included
	return (
		<div
			className={`home-size-container ${
				props.homeSizeSelected && 'home-size-container-selected-homesize'
			}`}>
			<div className="home-size-title-container">
				<p>Select home size</p>
			</div>
			<Carousel
				className={`carousel-container ${carouselClicked ? 'hide-arrows' : ''} ${props.isEmbed && 'embed'}`}
				activeIndex={activeIndex}
				onSelect={handleSelect}
				interval={10000000}
				indicators={true}>
				{slides}
			</Carousel>
		</div>
	);
};

export default connect(null, { setHomeSizeSelected })(
	TouchableHomeSizeCarousel
);
