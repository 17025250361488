import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './mapMarker/Marker';
import { connect } from 'react-redux';

const mapStateToProps = ({ movePlan, common }) => ({
	pick_up: movePlan.currentPlan.pick_up ? movePlan.currentPlan.pick_up : 
		movePlan.currentPlan.details ? 
		{...movePlan.currentPlan.details.pick_up, lat:movePlan.currentPlan.details.pick_up.latitude, lng: movePlan.currentPlan.details.pick_up.longitude}
	: null,
	drop_off: movePlan.currentPlan.drop_off ? movePlan.currentPlan.drop_off : 
		movePlan.currentPlan.details ? {...movePlan.currentPlan.details.drop_off, lat: movePlan.currentPlan.details.drop_off.latitude, lng: movePlan.currentPlan.details.drop_off.longitude} 
	: null,
	extra_drop_off: movePlan.currentPlan.drop_off
		? movePlan.currentPlan.extra_drop_off
		: null,
	moveMapTop: common.moveMapTop,
	homeSizeSelected: common.homeSizeSelected,
	toggledExtraDropOff: common.toggledExtraDropOff,
	inputFocusing: common.inputFocusing,
	isEmbed: common.isEmbed
});

const GoogleMap = ({
	coords,
	zoom,
	pick_up,
	drop_off,
	moveMapTop,
	homeSizeSelected,
	extra_drop_off,
	toggledExtraDropOff,
	inputFocusing,
	isEmbed
}) => {
	console.log('props GoogleMap comp',
	{
		coords,
		zoom,
		pick_up,
		drop_off,
		moveMapTop,
		homeSizeSelected,
		extra_drop_off,
		toggledExtraDropOff,
		inputFocusing,
		isEmbed
	}
	)
	const [directionsService, setDirectionsService] = useState(null);
	const [directionsRenderer, setDirectionsRenderer] = useState(null);
	const [mapLoaded, setMapLoaded] = useState(false);

	const calculateAndDisplayRoute = () => {
		let wayPoints = [];
		if (extra_drop_off) {
			wayPoints.push({
				location: extra_drop_off.lat + ',' + extra_drop_off.lng,
				stopover: true,
			});
		}
		directionsService.route(
			{
				travelMode: 'DRIVING',
				origin: pick_up.lat + ',' + pick_up.lng,
				destination: drop_off.lat + ',' + drop_off.lng,
				waypoints: wayPoints,
			},
			(DirectionsResult, DirectionsStatus) => {
				if (DirectionsStatus === 'OK') {
					directionsRenderer.setDirections(DirectionsResult);
				}
			}
		);
	};

	useEffect(() => {
		if (pick_up && drop_off && mapLoaded) {
			calculateAndDisplayRoute();
		}
		//eslint-disable-next-line
	}, [pick_up, drop_off, extra_drop_off]);

	useEffect(() => {
		if (pick_up && drop_off && mapLoaded) {
			calculateAndDisplayRoute();
		}
		//eslint-disable-next-line
	}, [mapLoaded]);

	// change addresses, google map won't be re-rendered again
	// So save the directionsService and directionsRenderer funtion
	const apiIsLoaded = (map, maps) => {
		const directionsService = new maps.DirectionsService();
		const directionsRenderer = new maps.DirectionsRenderer({
			map: map,
			polylineOptions: {
				strokeColor: 'black',
				strokeWeight: 5,
			},
			suppressMarkers: true,
			markerOptions: {
				shape: 'circle',
				color: 'green',
			},
		});
		setDirectionsService(directionsService);
		setDirectionsRenderer(directionsRenderer);
		setMapLoaded(true);
	};
	return (
		<div
			className={`geo-mapLayout ${
				toggledExtraDropOff && 'geo-mapLayout-65vh'
			} ${moveMapTop && 'geo-mapLayout-55vh'} 
			${
				homeSizeSelected && !isEmbed ? 
				'geo-mapLayout-40vh' : 
				homeSizeSelected && 'geo-mapLayout-35vh'
			} ${!drop_off.lat && 'hide-map'}`}>
			<GoogleMapReact
				defaultCenter={coords}
				defaultZoom={zoom}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}>
				{pick_up && (
					<Marker
						lat={pick_up.lat}
						lng={pick_up.lng}
						text={pick_up.city}
						iconType={'from'}
					/>
				)}
				{drop_off && (
					<Marker
						lat={drop_off.lat}
						lng={drop_off.lng}
						text={drop_off.city}
						iconType={'to'}
					/>
				)}
				{extra_drop_off && (
					<Marker
						lat={extra_drop_off.lat}
						lng={extra_drop_off.lng}
						text={extra_drop_off.city}
						iconType={'to'}
					/>
				)}
			</GoogleMapReact>
		</div>
	);
};

export default connect(mapStateToProps, {})(GoogleMap);
