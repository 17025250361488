import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DnaRow from './DnaRow/DnaRow';
import {
	setInputFocusing,
	setToggledExtraDropOff,
} from '../../../../redux/actions/common';
import { push } from 'connected-react-router';

const mapStateToProps = ({ movePlan, common }) => ({
	pick_up: movePlan.currentPlan.pick_up ? movePlan.currentPlan.pick_up : null,
	drop_off: movePlan.currentPlan.drop_off
		? movePlan.currentPlan.drop_off
		: null,
	toggledExtraDropOff: common.toggledExtraDropOff,
	inputFocusing: common.inputFocusing,
});

const DnaFormTop = props => {
	console.log('props from top', props)
	const [hightlightOrder, setHightlightOrder] = useState(0);
	const [extraStopToggle, setExtraStopToggle] = useState(false);

	const [pickUpError, setPickUpError] = useState(false);
	const [dropOffError, setDropOffError] = useState(false);

	useEffect(() => {
		if (props.pick_up) {
			if (props.values.pick_up.apartment_number) {
				setHightlightOrder(2);
				return;
			}
			setHightlightOrder(1);
		}
		//eslint-disable-next-line
	}, [props.values.pick_up, props.pick_up]);
	useEffect(() => {
		if (props.drop_off) {
			if (props.values.drop_off.apartment_number) {
				setHightlightOrder(-1);
				return;
			}
			setHightlightOrder(-1);
		}
		//eslint-disable-next-line
	}, [props.values.drop_off, props.drop_off]);

	useEffect(() => {
		props.setToggledExtraDropOff(extraStopToggle);
		//eslint-disable-next-line
	}, [extraStopToggle]);

	useEffect(() => {
		if (!props.inputFocusing) {
			if (!props.values.pick_up.geocoded_address && props.touched.pick_up) {
				setPickUpError(true);
			}
			if (!props.values.drop_off.geocoded_address && props.touched.drop_off) {
				setDropOffError(true);
			}
		} else {
			setPickUpError(false);
			setDropOffError(false);
		}

		//eslint-disable-next-line
	}, [props.inputFocusing]);

	// const backToLandingPage = () => {
	// 	props.setInputFocusing(false)
	// 	props.push('/')
	// }

	const handleExtraStopToggle = () => {
		setExtraStopToggle(!extraStopToggle);
	};

	return (
		<div
			className={`DnaForm-Top-container ${
				props.toggledExtraDropOff && 'DnaForm-Top-container-toggled-extra'
			}`}>
			<h1 className="dna-title">Enter your addresses</h1>
			<div className="address-panel">
				<div className="address-row">
					<div className="address-column-1">
						<div className="back-btn-container"></div>
					</div>
					<div className="address-column-2">
						<p className="address-text">From</p>
					</div>
					<div className="address-column-3">
						<div
							className={`DnaRow-geosuggest-div ${
								hightlightOrder === 0 && 'blue-border'
							} ${pickUpError && 'input-error'}`}>
							<DnaRow
								addressKey="pick_up"
								setFieldValue={props.setFieldValue}
								setFieldError={props.setFieldError}
								setFieldTouched={props.setFieldTouched}
								value={props.pick_up}
								placeholder={'Address, city or zip'}
								error={pickUpError}
								formValue={props.values.pick_up}
							/>
						</div>
					</div>
					<div className="address-column-4">
						<div
							className={`address-apt ${
								hightlightOrder === 1 && 'blue-border__apt'
							}`}>
							<input
								type="text"
								name={'pick_up.apartment_number'}
								placeholder="Apt"
								onChange={props.handleChange}
								onFocus={() => props.setInputFocusing(true)}
								onBlur={() => props.setInputFocusing(false)}
							/>
						</div>
					</div>
				</div>

				<div className="address-row">
					<div className="address-column-1">
						<div className="back-btn-container"></div>
					</div>
					<div className="address-column-2">
						<p className="address-text">To</p>
					</div>
					<div className="address-column-3">
						<div
							className={`DnaRow-geosuggest-div ${
								hightlightOrder === 2 && 'blue-border'
							}`}>
							<DnaRow
								addressKey="drop_off"
								setFieldValue={props.setFieldValue}
								setFieldError={props.setFieldError}
								setFieldTouched={props.setFieldTouched}
								value={props.drop_off}
								placeholder={'Address, city or zip'}
								error={dropOffError}
								formValue={props.values.drop_off}
							/>
						</div>
					</div>
					<div className="address-column-4">
						<div
							className={`address-apt ${
								hightlightOrder === 3 && 'blue-border__apt'
							}`}>
							<input
								type="text"
								name={'drop_off.apartment_number'}
								placeholder="Apt"
								onChange={props.handleChange}
								onFocus={() => props.setInputFocusing(true)}
								onBlur={() => props.setInputFocusing(false)}
							/>
						</div>
					</div>
				</div>
				<div className="address-row address-row-slider">
					<div className="address-column-1">
						<div className="back-btn-container"></div>
					</div>
					<div className="address-column-2">
						<div className="address-text"></div>
					</div>
					<div className="address-column-3">
						<span
							style={{ color: 'grey' }}
							onClick={() => handleExtraStopToggle()}>
							Add an extra stop
						</span>
					</div>
					<div className="address-column-4">
						<div className="extra-address-label-row">
							<label>
								<span
									className={`apple-switch-container float-right ${
										extraStopToggle && 'checked'
									}`}>
									<span
										className={`apple-switch-switch ${
											extraStopToggle && 'checked'
										}`}>
										<input
											type="checkbox"
											className="apple-switch-switch"
											name="extra_pick_up_enabled"
											onChange={() => handleExtraStopToggle()}></input>
									</span>
								</span>
							</label>
						</div>
					</div>
				</div>

				{extraStopToggle && (
					<div className="address-row">
						<div className="address-column-1">
							<div className="back-btn-container"></div>
						</div>
						<div className="address-column-2">
							<p className="address-text"></p>
						</div>
						<div className="address-column-3">
							<div className="DnaRow-extra-geosuggest-div">
								<DnaRow
									addressKey="extra_drop_off"
									setFieldValue={props.setFieldValue}
									setFieldError={props.setFieldError}
									placeholder={'Address, City, or Zip'}
									formValue={props.values.extra_drop_off}
									setFieldTouched={props.setFieldTouched}
								/>
							</div>
						</div>
						<div className="address-column-4">
							<div className="address-apt">
								<input
									type="text"
									name={'extra_drop_off.apartment_number'}
									placeholder="Apt"
									onChange={props.handleChange}
									onFocus={() => props.setInputFocusing(true)}
									onBlur={() => props.setInputFocusing(false)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default connect(mapStateToProps, {
	setInputFocusing,
	setToggledExtraDropOff,
	push,
})(DnaFormTop);
