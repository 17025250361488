import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import inventory from './inventory';
import moving from './ui/moving';
import mobile from './ui/mobile';
import funnel from './funnel';
import auth from './auth';
import modals from './modals';
import compare from './compare';
import consultations from './consultations';
import book from './book';
import details from './details';
import plan from './plan';
import analytics from './analytics';
import movePlan from './movePlan';
import myHome from './myHome';
import common from './common';
import mover from './mover'

export default history =>
	combineReducers({
		router: connectRouter(history),
		analytics,
		auth,
		book,
		common,
		compare,
		consultations,
		details,
		funnel,
		inventory,
		movePlan,
		modals,
		myHome,
		plan,
		ui: combineReducers({
			moving,
			mobile,
		}),
		mover
	});
