import { ACCEPT_JOB, DECLINE_JOB, SET_JOB_DECLINE_INFO, GET_MOVER_SERVICES, SET_MOVER_SERVICES } from '../actionTypes';

export const acceptJob = () => ({
	type: ACCEPT_JOB,
});

export const declineJob = () => ({
	type: DECLINE_JOB,
});

export const setJobDeclineInfo = payload => ({
	type: SET_JOB_DECLINE_INFO,
	payload: payload,
});

export const getMoverServices = payload => ({
	type: GET_MOVER_SERVICES,
})

export const setMoverServices = payload => ({
	type: SET_MOVER_SERVICES,
	payload: payload
})