import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { ArrowBackIos } from "@material-ui/icons";
import { addDays, format } from 'date-fns';
import { push } from 'connected-react-router';
import ScriptLoadContainer from '../../common/ScriptLoadContainer';
import GoogleMapReact from './GoogleMapReact';
import DnaFormTop from './dna/DnaFormTop';
import DnaFormBot from './dna/DnaFormBot';
import { submitDNA, submitDNAforEmbeds } from '../../../redux/actions/movePlan';
import { setHeaderMargin, setMoveMapTop, isMoveNowEmbed, setHomeSizeSelected } from '../../../redux/actions/common';

const mapStateToProps = ({ movePlan, common }) => ({
	pick_up: movePlan.currentPlan.pick_up ? movePlan.currentPlan.pick_up : movePlan.currentPlan.details ? movePlan.currentPlan.details.pick_up : null,
	drop_off: movePlan.currentPlan.drop_off
		? movePlan.currentPlan.drop_off
		: movePlan.currentPlan.details ? movePlan.currentPlan.details.drop_off : null,
	inputFocusing: common.inputFocusing,
	isEmbed: common.isEmbed,
	embedMoverId: common.embedMoverId,
	embedMoverSchemeColor: common.embedMoverSchemeColor,
	homeSizeSelected: common.homeSizeSelected,
	moveMapTop: common.moveMapTop,
	currentPlan: movePlan.currentPlan
});
const isBeingEmbed = () => {
	try {
	  return window.self !== window.top;
	} catch (e) {
	  return true;
	}
  };
const useQuery = () => {
	const {search} = useLocation()
	return React.useMemo( () => new URLSearchParams(search), [search] )
}

const LandingDna = props => {
	const handleBackFromReviewPage = () => {
		console.log('currentPlan', props.currentPlan)
		return props.moveMapTop
	}
	const valueToUseOnShowHomeSize = handleBackFromReviewPage()
	const coords = { lat: 40.763365, lng: -73.975654 };
	const [showHomeSize, setShowHomeSize] = useState(valueToUseOnShowHomeSize);
	const [userWentBack, setUserWentBack] = useState(false)

	useEffect(() => {
		props.setHeaderMargin(false);
		//eslint-disable-next-line
		if(props.homeSizeSelected){
			setShowHomeSize(true)
		}
		
	}, 
		//eslint-disable-next-line react-hooks/exhaustive-deps
	[]);


	

	const handleContinue = () => {
		window.dataLayer = window.dataLayer || [];
		if(!userWentBack){
			window.dataLayer.push({
				event: 'MoveNow Landing DNA',
				eventCategory: 'MoveNow Landing DNA',
				eventAction: 'MoveNow booking:started',
				eventLabel: '',
				eventValue: '',
			});
		}
		props.setMoveMapTop(true);
		setShowHomeSize(true);
	};

	const handleBack = () => {
		setUserWentBack(true)
		props.setHomeSizeSelected(false)
		props.setMoveMapTop(false);
		setShowHomeSize(false);
		!showHomeSize && props.push('/')
	}
	const {moverId} = useParams()
	let query = useQuery()
	let schemeColor = query.get('schemeColor')
	const {isMoveNowEmbed} = props
	useEffect(() => {
		console.log('validacion:', (true && !!moverId))
		if(isBeingEmbed()){
			isMoveNowEmbed({isEmbed: (true && !!moverId), moverParams: {moverId, schemeColor}})
		}
	})

	return (
		<Formik
			initialValues={{
				pick_up: props.pick_up ? props.pick_up : '',
				drop_off: props.drop_off ? props.drop_off : '',
				home_size_id: 1,
				move_date: addDays(Date.now(), 10),
				extra_drop_off: '',
				add_typicals: true,
			}}
			handleSubmit={e => e.preventDefault()}
			onSubmit={values => {
				let extra_drop_off_enabled = false;
				if (values.extra_drop_off) {
					extra_drop_off_enabled = true;
				}
				const valuesToSend = {
					pick_up_address: values.pick_up,
					drop_off_address: values.drop_off,
					extra_drop_off_address: values.extra_drop_off,
					extra_drop_off_enabled: extra_drop_off_enabled,
					home_size_id: values.home_size_id,
					move_date: format(values.move_date, 'MM/dd/yyyy'),
					address_src: 'gmaps',
					white_label_name: 'Move Now',
				}

				props.isEmbed && props.embedMoverId ?
					props.submitDNAforEmbeds({...valuesToSend, add_typicals: values.add_typicals})
				: 
					props.submitDNA(valuesToSend) 
			}}>
			{({
				values,
				touched,
				handleSubmit,
				isSubmitting,
				setFieldValue,
				setFieldError,
				setFieldTouched,
				handleBlur,
				handleChange,
			}) => (
				<>
				{
					(showHomeSize || !props.isEmbed) && (
						<button type='button' onClick={handleBack} className={'back-btn'} >
							<ArrowBackIos />
						</button>
					)
				}
					<form onSubmit={handleSubmit} noValidate>
						<ScriptLoadContainer
							script="google"
							src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`}>
							{!showHomeSize ? (
								<DnaFormTop
									touched={touched}
									values={values}
									setFieldValue={setFieldValue}
									setFieldError={setFieldError}
									setFieldTouched={setFieldTouched}
									handleChange={handleChange}
								/>
							) : (
								<DnaFormBot
									values={values}
									setFieldValue={setFieldValue}
									handleBlur={handleBlur}
									isSubmitting={isSubmitting}
								/>
							)}
							{props.pick_up && props.drop_off && (
								<GoogleMapReact coords={coords} zoom={11} />
							)}
						</ScriptLoadContainer>
						{!showHomeSize && (
							<button
								disabled={
									!values.drop_off.geocoded_address ||
									!values.pick_up.geocoded_address
								}
								type="button"
								onClick={() => handleContinue()}
								className={`continue-btn ${
									props.inputFocusing && props.viewModel === 'mobile'
										? ''
										: 'continue-btn-fixed'
								}`}
								style={props.embedMoverSchemeColor !== null 
									? 
										{
											backgroundColor: !values.drop_off.geocoded_address || !values.pick_up.geocoded_address
												?
													'grey'
												:
												props.embedMoverSchemeColor
										} 
									: 
										undefined
								}>
								<p>Continue</p>
							</button>
						)}
					</form>
				</>
			)}
		</Formik>
	);
};

export default connect(mapStateToProps, {
	submitDNA,
	submitDNAforEmbeds,
	setHeaderMargin,
	setMoveMapTop,
	setHomeSizeSelected,
	isMoveNowEmbed,
	push
})(LandingDna);
